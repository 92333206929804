<template>
  <div>
    <Header />
    <MainNav />
    <PageTitle :title="$t('checkout.checkout')" />

    <FormPersonalDetails @address-change="onAddressChange" />
    <FormShippingOptions />

    <div
      v-if="hasShippingAddress && addressChanged && shippingRates.length === 0"
      class="checkout-error"
    >
      <NoticeAddressChange
        @reload-shipping-options="reloadShippingOptions"
        :message="$t('checkout.addressChanged')"
      />
    </div>
    <FormPaymentMethod />

    <div v-if="error" class="checkout-error">
      <Notice :message="error" type="error" />
    </div>

    <ConfirmOrderSummary />

    <div class="container">
      <div class="checkout-buttons">
        <div class="checkout-buttons__button">
          <v-btn
            class="button-huge"
            color="#f75007"
            dark
            rounded
            to="/shopping-cart"
          >
            {{ $t("checkout.backToCart") }}
          </v-btn>
        </div>

        <div class="checkout-buttons__button">
          <v-btn class="button-huge" color="" dark rounded @click="confirm">
            {{ $t("checkout.confirmOrder") }}
          </v-btn>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/common/Header.vue";
import Footer from "@/components/common/Footer.vue";
import MainNav from "@/components/common/MainNav.vue";
import PageTitle from "@/components/common/PageTitle.vue";
import FormPersonalDetails from "@/components/checkout/FormPersonalDetails.vue";
import FormShippingOptions from "@/components/checkout/FormShippingOptions.vue";
import FormPaymentMethod from "@/components/checkout/FormPaymentMethod.vue";
import Notice from "@/components/common/Notice.vue";
import NoticeAddressChange from "@/components/checkout/NoticeAddressChange.vue";
import ConfirmOrderSummary from "@/components/checkout/ConfirmOrderSummary.vue";
import user from "@/mixins/user.js";

import { mapActions, mapState, mapGetters } from "vuex";
import router from "@/router";

export default {
  name: "ShopCheckout",
  mixins: [user],
  components: {
    Header,
    Footer,
    MainNav,
    PageTitle,
    FormPersonalDetails,
    FormShippingOptions,
    FormPaymentMethod,
    Notice,
    NoticeAddressChange,
    ConfirmOrderSummary,
  },

  data() {
    return {
      error: "",
      loadingShippingOptions: false,
      addressChanged: true,
    };
  },

  methods: {
    ...mapActions("cart", [
      "setCustomerData",
      "getShippingRates",
      "setShippingRates",
      "setShipping",
    ]),
    confirm() {
      this.error = "";
      if (this.canContinue) {
        router.push("/checkout-confirm");
      } else {
        if (!this.isCustomerDataComplete) {
          this.error = this.$t("checkout.completePersonalDetails");
        }
        if (!this.isShippingMethodSelected) {
          this.error = this.$t("checkout.completeShippingMethod");
        }
        if (!this.hasPaymentMethod) {
          this.error = this.$t("checkout.completePaymentMethod");
        }
      }
    },

    reloadShippingOptions() {
      this.addressChanged = false;
      this.getShippingRates();
    },

    onAddressChange() {
      this.addressChanged = true;
      this.setShippingRates([]);
      this.setShipping({ key: "method", value: "" });
      this.setShipping({ key: "cost", value: 0 });
      this.setShipping({ key: "carrier", value: "" });
      this.setShipping({ key: "code", value: "" });
      this.setShipping({ key: "shipment_id", value: "" });
      this.setShipping({ key: "rate_id", value: "" });
    },
  },

  computed: {
    ...mapGetters("cart", {
      total: "total",
      hasShippingAddress: "hasShippingAddress",
    }),
    ...mapState("cart", {
      items: "items",
      customer: "customer",
      shipping: "shipping",
      options: "options",
      shippingRates: "shippingRates",
    }),
    ...mapGetters("payment", { hasPaymentMethod: "hasPaymentMethod" }),

    isCustomerDataComplete() {
      return this.customer.name &&
        this.customer.surname &&
        this.customer.phone &&
        this.customer.address1 &&
        this.customer.city &&
        this.customer.region &&
        this.customer.postcode &&
        this.customer.email
        ? true
        : false;
    },

    isShippingMethodSelected() {
      const { method, cost } = this.shipping;
      return this.options.pickup ? true : cost > 0 && method !== "";
    },

    canContinue() {
      return (
        this.isCustomerDataComplete &&
        this.hasShippingAddress &&
        this.isShippingMethodSelected &&
        this.hasPaymentMethod
      );
    },
  },

  watch: {
    shippingRates() {
      // console.log("shipping rates changed", this.shippingRates);
      // this.addressChanged = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #ccc;
  flex-direction: column;
}

::v-deep .checkout-container {
  max-width: 70% !important;
}

.checkout-buttons {
  display: flex;
  flex-direction: column;
  padding: 10px 35px;
  &__title {
    padding: 10px;
    font-size: 24px;
    text-transform: uppercase;
    text-align: center;
    font-weight: 700;
    white-space: nowrap;
  }
  &__button {
    margin-bottom: 15px;
    button {
      width: 100%;
    }
  }
}

.checkout-error {
  padding: 0 20px;
  width: 70%;
  margin-left: auto;
  margin-right: auto;
}

.button-huge {
  height: 50px !important;
  font-size: 24px !important;
}

@media only screen and (min-width: 900px) {
  .checkout-buttons {
    flex-direction: row;
    justify-content: center;
    margin-top: 50px;
    width: 80%;
    text-align: center;
    &__title {
      margin-right: auto;
    }
    &__button {
      margin-left: 35px;
    }
  }
}
</style>
