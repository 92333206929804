<template>
  <div>
    <div class="checkout-container">
      <FormTitle
        class="checkout-container__title"
        :title="$t('checkout.shippingOptions')"
      />

      <div class="form-group">
        <v-radio-group v-model="pickup">
          <v-radio
            color="#f75007"
            :label="$t('checkout.shipToAddress')"
            :value="false"
          ></v-radio>
          <v-radio
            class="mt-1"
            :label="$t('checkout.pickUpFromOffice')"
            :value="true"
            color="#f75007"
          ></v-radio>
        </v-radio-group>
      </div>
    </div>

    <div class="checkout-container" v-if="!pickup">
      <FormTitle
        class="checkout-container__title"
        :title="$t('checkout.shippingMethod')"
      />

      <v-container v-if="isLoading">
        <v-row class="fill-height" align-content="center" justify="center">
          <v-col class="text-subtitle-1 text-center" cols="12">
            {{ $t("checkout.loadingShippingRates") }}
          </v-col>
          <v-col cols="6">
            <v-progress-linear
              color="#f75007"
              indeterminate
              rounded
              height="6"
            ></v-progress-linear>
          </v-col>
        </v-row>
      </v-container>

      <div v-if="!hasShippingAddress" class="checkout-container__notice">
        <Notice :message="$t('checkout.completeShippingAddress')" type="info" />
      </div>

      <div v-if="shippingRates.error" class="checkout-container__notice">
        <Notice
          :message="`${$t('checkout.couldNotGetRates')} ${shippingRates.error}`"
          type="error"
        />
      </div>

      <div class="form-group" v-if="shippingRates">
        <v-radio-group v-model="selectedShippingCode">
            <v-radio
              v-for="item in shippingRates"
              :key="item.name"
              color="#f75007"
              :label="`${item.name} $${item.rate}`"
              :value="item.code"
              @click="selectShippingMethod(item.code)"
            ></v-radio>
        </v-radio-group>
      </div>
    </div>
  </div>
</template>

<script>
import FormTitle from "@/components/common/FormTitle.vue";
import Notice from "@/components/common/Notice.vue";

import { mapActions, mapState, mapGetters } from "vuex";

export default {
  name: "FormShippingOptions",
  components: {
    FormTitle,
    Notice,
  },

  data: () => ({
    selectedShippingCode: "",
    isLoading: false,
  }),

  methods: {
    ...mapActions("cart", [
      "setOptions",
      "getShippingRates",
      "setShipping",
      "setShippingRates",
    ]),

    selectShippingMethod(code) {
      this.setShipping({ key: "code", value: code });
      
      const rate = this.shippingRates.find(item => item.code === code);
      this.setShipping({ key: "cost", value: rate.rate });
      this.setShipping({ key: "method", value: rate.name });
      this.setShipping({ key: "shipment_id", value: rate.shipment_id });
      this.setShipping({ key: "rate_id", value: rate.id });
      this.setShipping({ key: "carrier", value: rate.carrier });
    },
  },

  computed: {
    ...mapState("cart", {
      customer: "customer",
      options: "options",
      items: "items",
      shipping: "shipping",
      shippingRates: "shippingRates",
    }),

    ...mapGetters("cart", {
      hasShippingAddress: "hasShippingAddress",
    }),

    pickup: {
      get() {
        return this.options.pickup;
      },
      set(value) {
        this.setOptions({ key: "pickup", value });
        if (value) {
          this.setShipping({ key: "method", value: "" });
          this.setShipping({ key: "cost", value: 0 });
        }
      },
    },
  },

  watch: {
    async hasShippingAddress() {
      if (this.hasShippingAddress === true) {
        this.isLoading = true;
        await this.getShippingRates();
        this.isLoading = false;

        this.selectedShippingCode = "GroundAdvantage";
        this.selectShippingMethod(this.selectedShippingCode, "usps"); // select default
      } else {
        this.setShippingRates([]);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.checkout-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: auto;
  margin-left: auto;

  &__title {
    width: 50%;
  }

  &__notice {
    padding: 0 20px;
  }
}

.form-group {
  padding: 0 20px;
}

@media only screen and (min-width: 900px) and (max-width: 1300px) {
  .checkout-container {
    max-width: 75%;
  }

  .form-group {
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    justify-content: space-between;

    &__field {
      width: 50%;
      padding: 5px 20px 5px 5px;
    }
  }
}

@media only screen and (min-width: 1300px) {
  .checkout-container {
    max-width: 70%;
  }

  .form-group {
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    justify-content: start;
    &__field {
      width: 30%;
      max-width: 30%;
      padding: 5px 15px;
    }
  }
}
</style>
